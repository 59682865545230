<!-- Banner -->
<app-banner></app-banner>
<!-- /Banner -->

<!-- Header -->
<app-header style="display: contents"></app-header>

<!-- Main content -->
<app-main style="display: contents"></app-main>

<!-- Modals -->
<app-modal></app-modal>
<!-- /Modals -->

<!-- Modal overlay -->
<div class="modal-overlay" [ngStyle]="overlayActive() ? {opacity: 1, visibility: 'visible'} : {opacity: 0, visibility: 'hidden'}" (click)="onOverlayClick()"></div>
