<!-- Unstake panel -->
<div id="panel-unstake" class="panel {{active ? '' : 'hide'}}">
    <div class="grid unstake-container">
        <div class="grid-cell border-right">
            <!-- Supply title -->
            <div class="grid grid-center title" style="margin-bottom:5px;">
                <div class="grid-cell">
                    <h3>Unstake</h3>
                </div>
                <div class="grid-cell" *ngIf="userLoggedIn()">
                    <p class="label text-right">Wallet: <a href="javascript:void(0);" class="text-purple" (click)="onSicxBalanceClick($event)"><span class="value-wallet-sicx" >{{userSicxBalance | RndDwn | usFormat: true}}</span> sICX</a>
                    </p>
                </div>
            </div>

            <div class="grid grid-center input-asset stake">
                <div class="grid-cell">
                    <a href="javascript:void(0);" class="asset sicx swap-from">sICX</a>
                </div>

                <div class="grid-cell">
                    <input id="swap-from" pattern="[0-9]*" type="text" name="swap-from" placeholder="0"
                           class="{{inputUnstakeAmountGtUserSicxBalance() ? 'text-red' : ''}}"
                           [value]="unstakeInputAmount | RndDwn | usFormat: false : true"
                           (keyup)="onUnstakeInputKeyUp($event)"
                           (blur)="processUnstakeInput($event)"
                           (paste)="processUnstakeInput($event)"
                    >
                </div>
            </div>

            <div class="grid graphical-options" style="margin-top: 20px;max-width: 285px;margin-left: auto;margin-right: auto;">
                <div id="withdraw-wait" class="grid-cell text-center" (click)="onUnstakeWaitClick($event)">
                    <input class="checkbox-tools" type="radio" name="tools" id="tool-2" [checked]="unstakeWaitIsActive()">
                    <label class="for-checkbox-tools" for="tool-2">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" class="svg replaced-svg"><g fill="none" stroke="#7a8294" stroke-width="2" transform="translate(-1 -1)"><circle cx="12" cy="12" r="10"></circle><path d="m12 6v6l4 2"></path></g></svg>
                        <span class="text-bold">Wait</span>
                        <br>
                        <span>{{receivedIcxAmount | RndDwn | usFormat: true}} ICX</span>
                    </label>
                </div>

                <div class="grid-cell">
                    <div class="or"></div>
                </div>

                <div id="withdraw-keep" class="grid-cell text-center" (click)="onUnstakeInstantClick($event)">
                    <input #instantBox class="checkbox-tools" type="radio" name="tools" id="tool-3" [checked]="unstakeInstantIsActive()" [disabled]="instantLiquidityLtUnstakeAmount()">
                    <label class="for-checkbox-tools" for="tool-3">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 77 66" xmlns="http://www.w3.org/2000/svg" class="svg replaced-svg"><path d="m72.325 33.234v-2.947c0-5.389-3.698-9.919-8.686-11.217l-.009-4.859c0-4.742-3.859-8.601-8.603-8.601h-.455l-40.262 12.49c-.917.053-1.787.265-2.604.584h-.105c-6.396 0-11.601 5.205-11.601 11.603v28.804c0 6.397 5.204 11.603 11.601 11.603h49.123c6.396 0 11.601-5.205 11.601-11.603v-3.831c2.323-.899 3.979-3.151 3.979-5.789v-10.45c-.001-2.636-1.656-4.888-3.979-5.787zm-2.022 16.236c0 .118-.093.211-.211.211h-16.241c-.118 0-.21-.093-.21-.211v-10.449c0-.115.094-.209.21-.209h16.241c.116 0 .211.094.211.209zm-14.905-37.833c1.261.18 2.232 1.266 2.232 2.579l.008 4.469h-24.959zm5.326 53.056h-49.122c-3.093 0-5.601-2.509-5.601-5.603v-28.803c0-3.095 2.508-5.603 5.601-5.603h49.122c3.094 0 5.601 2.508 5.601 5.603v2.525h-12.474c-3.424 0-6.21 2.785-6.21 6.209v10.449c0 3.425 2.786 6.211 6.21 6.211h12.474v3.41c0 3.093-2.507 5.602-5.601 5.602z" fill="#7a8294" fill-rule="nonzero" transform="translate(0 -5.61)"></path></svg>
                        <span class="text-bold" >Instant</span>
                        <br>
                        <span>{{instantReceivedIcxAmount | RndDwn | usFormat: true}} ICX</span>
                    </label>
                </div>
            </div>

            <p id="wait-text" class="text-center label {{unstakeWaitIsActive() ? '' : 'hide'}}" style="margin-top: 5px; margin-bottom: 8px;">
                <ng-container *ngIf="unstakingTimesAreEqual()">
                    Join the unstaking queue to receive your ICX within {{maxUnstakingTimeInSeconds | secToDays: true}}.
                </ng-container>
                <ng-container *ngIf="!unstakingTimesAreEqual()">
                    Join the unstaking queue to receive your ICX within {{avgUnstakingTimeInSeconds | secToDays: false}}-{{maxUnstakingTimeInSeconds | secToDays: true}}.
                </ng-container>
            </p>

            <p id="instant-text" class="text-center label {{unstakeInstantIsActive() ? '' : 'hide'}}" style="margin-top: 5px; margin-bottom: 8px;">Pay a {{balancedDexFees?.icxTotal | rndDwnNPerc}} fee to swap your sICX for ICX.</p>

            <!-- Swap button -->
            <p class="text-center" style="margin-top: 0;">
                <a href="javascript:void(0);" class="button purple modal-trigger" data-modal="modal-unstake-confirm" (click)="onUnstakeClick($event)">{{userLoggedIn() ? 'Unstake' : 'Sign in'}}</a>
            </p>
        </div>

        <!-- Stats -->
        <div class="grid-cell stats-container">
            <!-- Supply title -->
            <div class="grid grid-center title">
                <div class="grid-cell">
                    <h3>Unstaking requests</h3>
                </div>
                <div class="grid-cell text-right">
                    <p><span class="text-purple" style="font-size: 20px;">{{unstakingAmount | RndDwn | usFormat}}{{unstakingAmount.gt(0) ? " ICX" : ""}}</span></p>
                </div>
            </div>

            <!-- Chart message -->
            <div style="height:140px;" class="grid grid-center" [ngStyle]="unstakingChart !== undefined || !chartFailedToLoad | hide">
                <div class="grid-cell">
                    <p class="label text-center">Chart data unavailable.</p>
                </div>
            </div>

            <!-- Loader -->
            <img src="assets/img/feature/loader.svg" [ngStyle]="unstakingChart !== undefined || chartFailedToLoad | hide" class="loader" alt="Loading..">

            <!-- Chart -->
            <div id="chart-line-2" class="chart-apy" [ngStyle]="unstakingChart === undefined || chartFailedToLoad | hide" #unstkApyChart></div>

            <div class="grid notice" style="margin-top: 0;">
                <div class="grid-cell text-center">
                    <dl class="metadata">
                        <dt>Unstaking time <span id="p-rep-tooltip" class="tooltip">
									<span class="tooltip-item"><img src="assets/img/icon/question.svg" class="tooltip-icon" alt=""></span>
									<div class="tooltip-content">
										<p>The last unstaking request took <span class="text-bold">{{avgUnstakingTimeInSeconds | SecToDh}}</span>.<br>
                                            The maximum unstaking time is currently <span class="text-bold">{{maxUnstakingTimeInSeconds | SecToDh}}</span>.
                                        </p>
									</div>
								</span></dt>
                        <dd>
                            <span *ngIf="unstakingTimesAreEqual()" class="value-liquidity-apy-4">{{avgUnstakingTimeInSeconds | secToDays: true}}</span>
                            <span *ngIf="!unstakingTimesAreEqual()" class="value-liquidity-apy-4">{{avgUnstakingTimeInSeconds | secToDays: false}}-{{maxUnstakingTimeInSeconds | secToDays: true}}</span>
                        </dd>
                    </dl>
                </div>
                <div class="grid-cell text-center">
                    <dl class="metadata">
                        <dt>Instant liquidity <span id="p-rep-tooltip" class="tooltip">
									<span class="tooltip-item"><img src="assets/img/icon/question.svg" class="tooltip-icon" alt=""></span>
									<div class="tooltip-content"><p>The amount of ICX available to swap via the Balanced exchange.</p></div>
								</span></dt>
                        <dd>
                            <span class="value-liquidity-apy-4 {{instantLiquidityLtUnstakeAmount() ? 'text-red' : ''}}">{{instantIcxLiquidity() | RndDwn:0 | usFormat}} {{instantIcxLiquidity().isZero() ? '' : 'ICX'}}</span>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
    <!-- Unstaking messgae -->
    <div class="notice" *ngIf="userLoggedIn() && (shouldShowUnstakeInfo() || claimableIcx.gt(0.01))">
        <ng-container *ngIf="shouldShowUnstakeInfo()">
            <ng-container *ngFor="let unstakeInfo of userUnstakeInfo?.data">
                <p class="label">
                    <span class="text-bold">{{unstakeInfo.amount | RndDwn | usFormat}} ICX</span> will be ready to claim within <span class="text-bold"
                >{{unstakeInfo | untilBlockTime: currentBlockHeight}}</span>. {{unstakeInfo.sicxBefore | RndDwn | usFormat: true}} ICX is ahead of yours in the unstaking queue.
                </p>
            </ng-container>
        </ng-container>
        <p *ngIf="claimableIcx && claimableIcx?.gt(0.01)" class="label text-white-in-dark">{{claimableIcx | RndDwn | usFormat}} ICX is ready to claim. <a href="javascript:void(0);" (click)="onClaimIcxClick($event)">Claim ICX.</a></p>
    </div>
</div>
