<!-- Header -->
<header class="header">
    <div class="grid grid-center">
        <div class="grid-cell">
            <div class="grid grid-center">
                <!-- Logo -->
                <div class="logo-container">
                    <span id="logo-back-container" class="tooltip hover">
                        <span class="tooltip-item">
                            <a href="https://omm.finance/" class="logo svg">
                                <img src="assets/img/logo/omm.svg" alt="Omm logo" class="logo">
                            </a>
                        </span>
                        <span id="logo-back" class="tooltip-content clearfix" style="margin-left: 145px;margin-bottom: -46px;width: 207px;">
                            <div class="grid">
                                <div class="grid-cell no-margin">
                                    <p class="text-white">Back to the Omm website</p>
                                </div>
                            </div>
                        </span>
                    </span>
                </div>
                <!-- Page title -->
                <div class="grid-cell">
                    <h1>{{pageTitle}}</h1>
                    <div class="refresh" (click)="onRefreshClick()">
                        <a href="javascript:void(0);" class="refresh-button">
                            <img src="assets/img/icon/exchange.svg" alt="refresh">
                        </a>
                    </div>
                    <p class="text-purple" style="font-size: 20px;" [ngStyle]="isProduction() | hide">{{network()}} testnet</p>
                </div>
            </div>
        </div>

        <!-- Utilities -->
        <div class="grid-cell" [ngStyle]="!userLoggedIn() | hide" (clickOutside)="onClickOutsideMenu()">
            <!-- Bridge wallet -->
            <div class="grid grid-center wallet bridge {{dropdownOpen ? 'active' : ''}}" (click)="onWalletClick($event)">
                <div class="grid-cell text-right">
                    <p class="text-dark">{{getWalletName()}}</p>
                    <span class="label">{{getWalletId()}}</span>
                </div>
                <div class="grid-cell">
                    <div class="dot">
                        <img src="assets/img/icon/wallet.svg" class="svg" alt="Wallet icon" width="50px">
                    </div>
                </div>
            </div>

            <div class="grid wallet-content bridge panel {{dropdownOpen ? 'active' : ''}}">
                <div class="grid-cell">
                    <ul>
                        <li id="menu-copy"><a href="javascript:void(0);" (click)="onCopyIconAddressClick($event)" class="animation-underline">Copy ICON address</a></li>
                        <li id="menu-change"><a href="javascript:void(0);" class="animation-underline" (click)="onSignInClick()">Change wallet</a></li>
                    </ul>
                    <p class="text-center"><a href="javascript:void(0);" class="button" (click)="onSignOutClick()">Sign out</a></p>
                </div>
            </div>
        </div>

        <!-- Sign in button -->
        <div class="text-right sign-in-container" [ngStyle]="userLoggedIn() | hide">
            <a href="javascript:void(0);" id="sign-in-btn" class="button modal-trigger" data-modal="modal-sign-in" (click)="onSignInClick()">Sign in</a>
        </div>
    </div>
</header>
