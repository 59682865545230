<!-- Stake content -->
<div class="grid-cell">
    <main>
        <app-stake-overview style="display: contents">

        </app-stake-overview>

        <!-- Stake -->
        <section class="">
            <div class="grid">
                <div class="grid-cell">
                    <h2>Staked ICX</h2>
                    <ul class="toggle">
                        <li id="toggle-stake" class="{{stakePanelActive() ? 'active' : ''}}" (click)="onStakeToggleClick($event)">Stake</li>
                        <li id="toggle-unstake" class="{{unstakePanelActive() ? 'active' : ''}}" (click)="onUnstakeToggleClick($event)">Unstake</li>
                    </ul>
                </div>
            </div>

            <app-stake-panel style="display: contents" [active]="stakePanelActive()"
            >

            </app-stake-panel>

            <app-unstake-panel style="display: contents" [active]="unstakePanelActive()"
            >

            </app-unstake-panel>
        </section>
    </main>
</div>
