<!-- Stake panel -->
<div id="panel-stake" class="panel {{active ? '' : 'hide'}}">
    <div class="grid stake-container">
        <!-- Inputs -->
        <div class="grid-cell border-right">
            <!-- Supply title -->
            <div class="grid grid-center title">
                <div class="grid-cell">
                    <h3>Stake</h3>
                </div>
                <div class="grid-cell" *ngIf="userLoggedIn()">
                    <p class="label text-right">Wallet: <a href="javascript:void(0);" class="text-green" (click)="onIcxBalanceClick($event)">
                        <span class="value-wallet-icx">{{userIcxBalance | RndDwn | usFormat: true}}</span> ICX</a>
                    </p>
                </div>
            </div>
            <!-- Swap from -->
            <div class="grid grid-center input-asset stake">
                <div class="grid-cell">
                    <a href="javascript:void(0);" class="asset icx swap-from">ICX</a>
                </div>

                <div class="grid-cell">
                    <input id="swap-from" pattern="[0-9]*" type="text" name="swap-from" placeholder="0"
                           class="{{userIcxBalanceLtInputAmount() ? 'text-red' : ''}}"
                           [value]="stakeInputAmount | RndDwn | usFormat: false : true"
                           (keyup)="onStakeInputKeyUp($event)"
                           (blur)="processStakeInput($event)"
                           (paste)="processStakeInput($event)"
                    >
                </div>
            </div>

            <!-- Swap to -->
            <div class="grid grid-center">
                <div class="grid-cell"><label for="swap-to">Receive</label></div>
                <div class="grid-cell" *ngIf="userLoggedIn()">
                    <p class="label text-right">Wallet: {{userSicxBalance | RndDwn | usFormat: true}} sICX</p>
                </div>
            </div>
            <div class="grid grid-center input-asset unstake">
                <div class="grid-cell">
                    <a href="javascript:void(0);"  class="asset sicx swap-to">sICX</a>
                </div>

                <div class="grid-cell">
                    <input id="swap-to" pattern="[0-9]*" type="text" name="swap-to" placeholder="0"
                           [value]="unstakeInputAmount | RndDwn | usFormat: false : true"
                           (keyup)="onUnstakeInputKeyUp($event)"
                           (blur)="processUnstakeInput($event)"
                           (paste)="processUnstakeInput($event)"
                    >
                </div>
            </div>

            <!-- Swap button -->
            <p class="text-center"><a href="javascript:void(0);" class="button modal-trigger" data-modal="modal-stake-confirm" (click)="onStakeClick($event)">{{userLoggedIn() ? 'Stake' : 'Sign in'}}</a></p>
        </div>

        <!-- Stats -->
        <div class="grid-cell stats-container">
            <!-- Supply title -->
            <div class="grid grid-center title">
                <div class="grid-cell">
                    <h3>Staking APR
                        <span class="tooltip">
                            <span class="tooltip-item">
                                <img src="assets/img/icon/question.svg" class="tooltip-icon" alt="">
                            </span>
                            <div class="tooltip-content" style="min-width: 320px;">
                            <p>The traditional ICX staking return ({{stakingApr.dividedBy(ONE.minus(stakingFee)) | rndDwnNPerc: 2}}), minus a 10% fee.</p>
                            </div>
                        </span>
                    </h3>
                </div>
                <div class="grid-cell text-right">
                    <p><span class="supply-apy text-green">{{stakingApr | rndDwnNPerc: 2}}</span></p>
                </div>
            </div>

            <!-- Chart message -->
            <div style="height:140px;" class="grid grid-center" [ngStyle]="stakingAprChart !== undefined || !chartFailedToLoad | hide">
                <div class="grid-cell">
                    <p class="label text-center">Chart data unavailable.</p>
                </div>
            </div>

            <!-- Loader -->
            <img src="assets/img/feature/loader.svg" [ngStyle]="stakingAprChart !== undefined || chartFailedToLoad | hide" class="loader" alt="Loading..">

            <!-- Chart -->
            <div id="chart-line-1" class="chart-apy" #stkAprChart [ngStyle]="stakingAprChart === undefined || chartFailedToLoad | hide"></div>

            <div class="grid notice">
                <div class="grid-cell text-center">
                    <dl class="metadata">
                        <dt>ICX price</dt>
                        <dd><span class="value-liquidity-apy-4">{{icxPrice | dollarUsLocale}}</span></dd>
                    </dl>
                </div>
                <div class="grid-cell text-center">
                    <dl class="metadata">
                        <dt>sICX price</dt>
                        <dd><span class="value-liquidity-apy-4">{{sicxPrice | dollarUsLocale}}</span></dd>
                    </dl>
                </div>
                <div class="grid-cell text-center">
                    <dl class="metadata">
                        <dt>Exchange rate</dt>
                        <dd><span class="value-liquidity-apy-4">{{todaySicxRate | RndDwn: 4 | usFormat}}</span></dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>

    <!-- Unstaking messgae -->
    <div class="notice" *ngIf="userLoggedIn() && (shouldShowUnstakeInfo() || claimableIcx.gt(0.01))">
        <ng-container *ngIf="shouldShowUnstakeInfo()">
            <ng-container *ngFor="let unstakeInfo of userUnstakeInfo!.data">
                <p class="label">
                    <span class="text-bold">{{unstakeInfo.amount | RndDwn | usFormat}} ICX</span> will be ready to claim within <span class="text-bold"
                >{{unstakeInfo | untilBlockTime: currentBlockHeight}}</span>. {{unstakeInfo.sicxBefore | RndDwn | usFormat: true}} ICX is ahead of yours in the unstaking queue.
                </p>
            </ng-container>
        </ng-container>
        <p *ngIf="claimableIcx && claimableIcx?.gt(0.01)" class="label text-white-in-dark">{{claimableIcx | RndDwn | usFormat}} ICX is ready to claim. <a href="javascript:void(0);" (click)="onClaimIcxClick($event)">Claim ICX.</a></p>
    </div>
</div>
