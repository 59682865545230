export enum ModalType {
  STAKE_ICX,
  UNSTAKE_WAIT_SICX,
  UNSTAKE_INSTANT_SICX,
  SIGN_IN,
  LEDGER_LOGIN,
  CLAIM_REWARDS,
  CLAIM_ICX,
  STAKE_OMM_TOKENS,
  UNSTAKE_OMM_TOKENS,
  CANCEL_UNSTAKE_OMM_TOKENS,
  UPDATE_DELEGATIONS,
  REMOVE_ALL_DELEGATIONS,
  CAST_VOTE,
  CANCEL_VOTE,
  SUBMIT_PROPOSAL,
  LOCK_OMM,
  INCREASE_LOCK_OMM,
  INCREASE_LOCK_TIME,
  INCREASE_LOCK_TIME_AND_AMOUNT,
  APPLY_BOMM_BOOST,
  CLAIM_AND_APPLY_BOMM_BOOST,
  WITHDRAW_LOCKED_OMM,
  LOADING,
  UNDEFINED,
}
