<!-- Modal: Boost confirm -->
<div class="modal {{active ? 'modal-show' : ''}}" id="modal-boost-confirm">
    <div class="modal-content">
        <!-- Title -->
        <h3 class="text-center label">Lock up Omm Tokens?</h3>
        <p class="text-center">{{amount().toFixed(0) | usFormat: true}} OMM</p>

        <!-- Before vs after -->
        <div class="grid before-after">
            <div class="grid-cell text-center border-right">
                <p class="label">Before</p>
                <p class="text-dark">{{before().toFixed(0) | usFormat: true}} OMM</p>
            </div>
            <div class="grid-cell text-center">
                <p class="label">After</p>
                <p class="text-dark">{{after().toFixed(0) | usFormat}} OMM</p>
            </div>
        </div>

        <!-- Lock message -->
        <p class="label text-center">Your OMM will be locked until<br><span class="text-bold">{{
            timestampInMillisecondsToPrettyDate(lockingTime())}}</span>.</p>
        <p class="label text-center text-red" style="margin-top: 10px;">You won't be able to withdraw it early.</p>

        <!-- Modal actions -->
        <div class="modal-actions">
            <a href="javascript:void(0);" class="cancel modal-close" (click)="onCancelClick($event)">Cancel</a>
            <a href="javascript:void(0);" class="button" (click)="onConfirmLockUpOmmClick($event)">{{
                lockOmmProcessing ? "Locking up OMM..." : "Lock up OMM"}}</a>
        </div>
    </div>
</div>
