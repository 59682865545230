<app-sign-in-modal style="display: contents"
                   [active]="isModalActive(ModalType.SIGN_IN)"
>
</app-sign-in-modal>

<app-ledger-login-modal style="display: contents"
                        [active]="isModalActive(ModalType.LEDGER_LOGIN)"
>
</app-ledger-login-modal>

<app-stake-modal style="display: contents"
                 [active]="isModalActive(ModalType.STAKE_ICX)"
                 [stakeIcxPayload]="stakeIcxPayload"
>
</app-stake-modal>

<app-unstake-wait-modal style="display: contents"
                        [active]="isModalActive(ModalType.UNSTAKE_WAIT_SICX)"
                        [unstakeWaitSicxPayload]="unstakeWaitSicxPayload"
>
</app-unstake-wait-modal>

<app-unstake-instant-modal style="display: contents"
                           [active]="isModalActive(ModalType.UNSTAKE_INSTANT_SICX)"
                           [unstakeInstantSicxPayload]="unstakeInstantSicxPayload"
>
</app-unstake-instant-modal>

<app-claim-icx-modal style="display: contents"
                     [active]="isModalActive(ModalType.CLAIM_ICX)"
                     [claimIcxPayload]="claimIcxPayload"
>
</app-claim-icx-modal>

<app-withdraw-omn-modal style="display: contents"
                        [active]="isModalActive(ModalType.WITHDRAW_LOCKED_OMM)"
                        [payload]="withdrawLockedOmmPayload"
>
</app-withdraw-omn-modal>

<app-lock-omm-modal style="display: contents"
                    [active]="modalIsLockingType()"
                    [payload]="ommLockingPayload"
>
</app-lock-omm-modal>

<app-submit-proposal-modal style="display: contents"
                           [active]="isModalActive(ModalType.SUBMIT_PROPOSAL)"
                           [payload]="submitProposalPayload"
>
</app-submit-proposal-modal>

<app-submit-vote-modal style="display: contents"
                       [active]="isModalActive(ModalType.CAST_VOTE)"
                       [payload]="governanceVotePayload"
>

</app-submit-vote-modal>

<app-claim-rewards-modal style="display: contents"
                         [active]="isModalActive(ModalType.CLAIM_REWARDS)"
                         [payload]="claimRewardsPayload"
>
</app-claim-rewards-modal>

<app-update-delegations-modal style="display: contents"
                              [active]="isModalActive(ModalType.UPDATE_DELEGATIONS)"
                              [payload]="updateDelegationPayload"
>
</app-update-delegations-modal>

<app-remove-delegations-modal style="display: contents"
                              [active]="isModalActive(ModalType.REMOVE_ALL_DELEGATIONS)"
                              [payload]="removeDelegationsPayload"
>
</app-remove-delegations-modal>
