<!-- Modal: Submit proposal -->
<div class="modal {{active ? 'modal-show' : ''}}" id="modal-submit-proposal">
    <div class="modal-content">
        <!-- Title -->
        <h3 class="text-center label">Submit proposal?</h3>
        <p class="text-bold text-center">{{voteDefinitionFee() | RndDwn | usFormat}} OMM</p>

        <!-- When / Value -->
        <div class="grid before-after" style="margin-bottom: 0px; margin-top: 10px;max-width: 220px;margin-left: auto;margin-right: auto;">
            <div class="grid-cell text-center">
                <p class="label">Voting will begin immediately, and end after {{getVoteDuration()}}.</p>
            </div>
        </div>

        <!-- Modal actions -->
        <div class="modal-actions">
            <a href="javascript:void(0);" class="cancel modal-close" (click)="onCancelClick($event)">Go back</a>
            <a href="javascript:void(0);" class="button" (click)="onSubmitProposalClick($event)">Submit proposal</a>
        </div>
    </div>
</div>
