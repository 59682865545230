<section class="overview">
    <!-- Staking overview -->
    <div id="staking-overview" class="grid panel">
        <div class="grid-cell">
            <div class="grid">
                <!-- ICX staked -->
                <div class="grid-cell text-center border-right">
                    <dl class="metadata">
                        <dt>ICX staked</dt>
                        <dd>{{totalIcxStaked.toFixed(0) | usFormat}}</dd>
                    </dl>
                </div>

                <!-- Total sICX -->
                <div class="grid-cell text-center border-right">
                    <dl class="metadata">
                        <dt>Total sICX</dt>
                        <dd>{{totalSicxAmount.toFixed(0) | usFormat}}</dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="grid-cell">
            <div class="grid">
                <!-- sICX holders -->
                <div class="grid-cell text-center border-right">
                    <dl class="metadata">
                        <dt>sICX holders</dt>
                        <dd>{{sicxHolders.toFixed(0) | usFormat}}</dd>
                    </dl>
                </div>

                <!-- Fees collected (7D) -->
                <div class="grid-cell text-center">
                    <dl class="metadata">
                        <dt>Fees collected (7D)</dt>
                        <dd *ngIf="feeDistributed7D.gt(0)">{{feeDistributed7D.toFixed(0) | usFormat: true}} <span class="label">ICX</span></dd>
                        <dd *ngIf="!feeDistributed7D.gt(0)">-</dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</section>
